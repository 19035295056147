// noinspection ES6ConvertVarToLetConst

(function(w, d) {
  'use strict';
  var userAgent = w.userAgent || w.navigator.userAgent;
  var onlyForNotSupportedBrowser = d.getElementById('not-supported-browser');
  var isIE = isInternetExplorer(userAgent);
  var isFFiPhone = isFirefoxOnIPhone(userAgent);

  if (isIE || isFFiPhone) {
    var angularContainer = d.getElementsByTagName('app-root')[0];
    var ieFooter = d.getElementById('nsb-footer');
    var linkToCSS = document.createElement('link');

    linkToCSS.setAttribute('rel', 'stylesheet');
    linkToCSS.setAttribute('href', 'only-for-nsb.css');
    d.body.insertAdjacentElement('afterbegin', linkToCSS);

    if (isIE) {
      var ieNotify = d.getElementById('ie-notify');

      if (isIE9OrGreater()) {
        ieNotify.className = 'ie-center';
      } else {
        ieFooter.className = 'no-display';
      }
    } else {
      onlyForNotSupportedBrowser.classList.add('ff');
    }
    if (angularContainer) angularContainer.style.display = 'none';
    if (onlyForNotSupportedBrowser) onlyForNotSupportedBrowser.style.display = 'block';
  } else {
    onlyForNotSupportedBrowser.remove();
  }

  function isInternetExplorer(userAgent) {
    // It was taken from https://www.whatismybrowser.com/guides/the-latest-user-agent/internet-explorer
    return new RegExp(/Mozilla\/[45].*(compatible; MSIE)?Windows NT.*Trident\/[4-7].0/).test(userAgent);
  }

  function isIE9OrGreater() {
    return !!d.addEventListener;
  }

  function isFirefoxOnIPhone(userAgent) {
    // It was taken from https://www.whatismybrowser.com/guides/the-latest-user-agent/firefox
    return new RegExp(/Mozilla\/5.0 \(iPhone;.*FxiOS\//).test(userAgent);
  }
})(window, document);
